import { useEffect, useRef, useState } from "react";
import "dotenv/config";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import useDidMountEffect from "./useDidMount";
import { from, interval, switchMap, tap } from "rxjs";
import WalletLink from "walletlink";

/**
 * Gets web3 wallet infos
 * @returns connect,
    address,
    slicedAddress,
    balance,
    web3,
    gas
 */
const useWallet = () => {
  const [address, setAddress] = useState(null);
  const [slicedAddress, setSlicedAddress] = useState(null);
  const [balance, setBalance] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [gas, setGas] = useState(null);
  const subscription = useRef(null);

  useDidMountEffect(() => {
    if (!address) {
      return;
    }
    const addr = `${address.slice(0, 5)}...${address.slice(
      address.length - 4
    )}`;
    setSlicedAddress(addr);
  }, [address]);

  useDidMountEffect(() => {
    if (!address) {
      return;
    }

    web3.eth.getBalance(address, (err, bal) => {
      setBalance(bal);
    });
  }, [address]);

  useDidMountEffect(() => {
    if (!address) {
      return;
    }

    subscription.current = interval(1000)
      .pipe(
        switchMap(() => from(web3.eth.getGasPrice())),
        tap((val) => setGas(Math.round(web3.utils.fromWei(val, "gwei"))))
      )
      .subscribe();
  }, [address]);

  useEffect(
    () => () => {
      subscription.current && subscription.current.unsubscribe();
    },
    []
  );

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: process.env.REACT_APP_INFURA_ID,
      },
    },
    "custom-walletlink": {
      display: {
        logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
        name: "Coinbase Wallet",
        description: "Connect to Coinbase Wallet (not Coinbase App)",
      },
      options: {
        appName: "Coinbase", // Your app name
        networkUrl:
          "https://mainnet.infura.io/v3/ff4d86e7cf95410ab58f524c7a8204f1",
        chainId: 1,
      },
      package: WalletLink,
      connector: async (_, options) => {
        const { appName, networkUrl, chainId } = options;
        const walletLink = new WalletLink({
          appName,
        });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions,
  });

  /**
   * Creates connection between wallet and site
   */
  const connect = async () => {
    const provider = await web3Modal.connect();
    setWeb3(new Web3(provider));
    const w = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    setAddress(w[0]);
  };

  return {
    connect,
    address,
    slicedAddress,
    balance,
    web3,
    gas,
  };
};

export default useWallet;
