const SlideOut = (props) => {
  const direction = props.direction ?? "left";
  const content = props.content;
  return direction === "left" ? (
    <div className="flex">
      <div
        style={content ? {} : { transform: "translateX(101%)" }}
        className="flex items-center py-2 px-3 rounded-2xl bg-white text-black pr-12 transform translate-x-10 transition-transform ease-in-out duration-200"
      >
        <span>{content}</span>
      </div>
      <div className="relative py-2 px-6 rounded-2xl bg-gray uppercase text-white flex items-center">
        {props.children}
      </div>
    </div>
  ) : (
    <div className="flex">
      <div className="relative z-10 py-2 px-6 rounded-2xl bg-gray uppercase text-white flex items-center">
        {props.children}
      </div>
      <div
        style={content ? {} : { transform: "translateX(-101%)" }}
        className="flex items-center py-2 px-3 rounded-2xl bg-white text-black pl-12 transform -translate-x-10 transition-transform ease-in-out duration-200"
      >
        <span>{content}</span>
      </div>
    </div>
  );
};

export default SlideOut;
