import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

import logo from "../assets/Logo.png";

import discord from "../assets/icons/discord.svg";
import twitter from "../assets/icons/twitter.svg";
import insta from "../assets/icons/instagram.svg";

import main from "../assets/Main.png";
import rarity from "../assets/rarity.gif";
import gallery from "../assets/Gallery.png";

import phase1 from "../assets/phases/phase1.png";
import phase2 from "../assets/phases/phase2.png";
import phase3 from "../assets/phases/phase3.png";
import phase4 from "../assets/phases/phase4.png";

import ash from "../assets/creators/ash.png";
import tye from "../assets/creators/tye.png";
import michael from "../assets/creators/michael.png";
import { Link } from "react-router-dom";

const Home = () => {
  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    setIsShowing(true);
  }, [isShowing]);

  return (
    <Transition
      show={isShowing}
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <nav className="py-10 md:py-20 flex justify-between items-center mb-14">
        <div className="w-1/2 md:w-auto aspect-auto">
          <img alt="Logo" src={logo} />
        </div>
        <div className="space-x-6 flex">
          <a
            href="https://discord.gg/rockpals"
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7"
          >
            <img alt="discord" src={discord} />
          </a>
          <a
            href="https://twitter.com/rockpalsnft"
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7"
          >
            <img alt="twitter" src={twitter} />
          </a>
          <a
            href="https://www.instagram.com/rockpalsnft/"
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7"
          >
            <img alt="instagram" src={insta} />
          </a>
        </div>
      </nav>

      <header className="flex flex-col-reverse md:flex-row md:space-x-18 space-x-0 mb-24 md:mb-48">
        <div className="w-full md:w-1/2 space-y-12 md:space-y-24 font-display">
          <Transition.Child
            enter="transition-opacity ease-linear duration-1400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <h1 className=" uppercase font-bold text-2xl sm:text-4xl md:text-5xl leading-relaxed">
              Remember pet rocks? <br /> We're on the <br />
              <b className="text-yellow">Blockchain Now</b>
            </h1>
          </Transition.Child>

          <Transition.Child
            enter="transition-opacity ease-linear duration-2000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <Link
              className="w-full md:w-1/2 flex justify-center items-center py-4 px-24 bg-discord uppercase rounded-md"
              to={"/mint"}
            >
              Mint now!
            </Link>
          </Transition.Child>
        </div>
        <div className="w-full md:w-1/2">
          <img alt="hero" src={main} className="md:mt-8" />
        </div>
      </header>

      <section className="mx-auto planetary flex flex-col items-center justify-center space-y-16 mb-32">
        <p className="text-center max-w-2xl">
          These strange and mysterious Rock beings were found on a distant
          planet far away....
        </p>
        <p className="text-center max-w-2xl">
          Thirteen Rock species thrived, different at base but alike in build.
          Their goal? To restore every planet in the galaxy and Earth is next.
          All Rocks are unique. All Rocks have purpose. All Rocks mobilize to
          build a better tomorrow.
        </p>

        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-10 justify-center">
          <div className="glass px-16 py-12 flex items-center justify-center">
            <p className="text-center md:text-left">
              Individually they work daily to provide you $PEBBLES and accompany
              you in your real-life through a 3D model.
            </p>
          </div>
          <div className="glass px-16 py-12 flex items-center justify-center">
            <p className="text-center md:text-left">
              Now, together these mission-driven Rock Pals live for something
              greater - a better life on Earth. Charities working to clean
              oceans & reduce carbon emissions will receive proceeds as Rock
              Pals are forged.
            </p>
          </div>
        </div>

        <span className="text-center md:text-left">
          "Only a life lived for others is a life worthwhile." -{" "}
          <b className="text-yellow">Albert Einstein</b>
        </span>
      </section>

      <section className="w-full md:w-3/4 mx-auto aspect-video flex justify-center mb-32">
        <img alt="Rarity chart" src={rarity} />
      </section>

      <section className="mx-auto flex flex-col md:flex-row items-center md:items-start justify-center space-y-16 md:space-y-0 md:space-x-24 mb-32 md:mb-60">
        <div className="flex flex-col space-y-16 w-full md:w-1/2">
          <span className=" space-y-7">
            <p className="text-center md:text-left">
              <b className="text-yellow">Rock Pals</b> is a collection of
              smirk-faced, styled out googly eye rocks on the Ethereum
              Blockchain. Just like art, NFTs owners want to display their rare
              collectibles so we placed our Rocks on podiums. With possible
              attributes like red velvet rope & studio lighting, your NFTs
              become more than a profile pic.
            </p>
            <p className="text-center md:text-left">
              As the <b className="text-clay">launch date</b> gets closer our
              website will update to allow minting of one, three, or ten Rocks.
              Our roadmap is a teaser for what's coming post-launch. Join our
              discord and say hello to your pals.
            </p>
          </span>
          <a
            href="https://discord.gg/rockpals"
            target="_blank"
            rel="noreferrer"
            className="w-full md:w-auto flex justify-center items-center py-4 px-24 bg-discord uppercase rounded-md self-start"
          >
            Discord
          </a>
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center md:items-start space-y-4">
          <div>
            <img alt="gallery" src={gallery} />
          </div>
          <div>
            <h3 className="uppercase text-yellow font-bold text-center md:text-left">
              Bring your NFT art to life
            </h3>
            <p className="text-sm text-center md:text-left">
              Tokenframe: sold separately
            </p>
          </div>
        </div>
      </section>

      <section className="mx-auto flex flex-col items-center space-y-20 mb-60">
        <h2 className="text-center font-display uppercase text-5xl font-bold">
          Roadmap <b className="text-yellow">Guide</b>
        </h2>
        <div className="flex flex-col lg:flex-row space-y-16 lg:space-y-0 lg:space-x-6">
          <div className="flex flex-col items-center">
            <div className="aspect-square lg:h-48 xl:h-64">
              <img alt="Phase 1" src={phase1} />
            </div>
            <div>
              <h3 className="text-center text-3xl font-bold mb-4">
                Phase <b className="text-yellow">1</b>
              </h3>
              <span className="space-y-2">
                <p className="text-center">
                  Merch Store <br />
                  ETH Giveaway <br />
                  NFT Airdrop Raffle to Holders <br />
                  $10K Donated <br />
                </p>
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="aspect-square lg:h-48 xl:h-64">
              <img alt="phase 2" src={phase2} />
            </div>
            <div>
              <h3 className="text-center text-3xl font-bold mb-4">
                Phase <b className="text-yellow">2</b>
              </h3>
              <span className="space-y-2">
                <p className="text-center">
                  3D Model <br />
                  10 ETH Stored in Liquidity Pool <br />
                  $20K Donated <br />
                </p>
                <p className="text-center md:text-left"></p>
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="aspect-square lg:h-48 xl:h-64">
              <img alt="phase 3" src={phase3} />
            </div>
            <div>
              <h3 className="text-center text-3xl font-bold mb-4">
                Phase <b className="text-yellow">3</b>
              </h3>
              <span className="space-y-2">
                <p className="text-center">
                  $PEBBLES token for Holders <br />
                  Metaverse Gallery <br />
                  Rarity.Tools Integration <br />
                  $30K Donated <br />
                </p>
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="aspect-square lg:h-48 xl:h-64">
              <img className="h-full" alt="phase 4" src={phase4} />
            </div>
            <div>
              <h3 className="text-center text-3xl font-bold mb-4">
                Phase <b className="text-yellow">4: The Big Bang</b>
              </h3>
              <span className="space-y-2">
                <p className="text-center">
                  When you combine $Pebbles with 2 burned Rock Pals you'll
                  create a whole new world....
                </p>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="mx-auto flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16 mb-32">
        <div className="flex flex-col md:w-1/3 space-y-4">
          <div>
            <img alt="Ash" src={ash} />
          </div>
          <div>
            <h3 className="text-4xl mb-1">Ash G.</h3>
            <h4 className="text-yellow mb-6">Marketing Wizard & Crypto Guru</h4>
            <p className="text-sm mb-8">
              He's a database corporate worker by day and a crypto aficionado
              morning, day, and night. With his passion for crypto starting in
              early 2017, the universe outside of centralized currencies was
              presented to the rest of the team. Some call him the most
              interesting man in the world, others call him Big Ash-ko.
            </p>
            <p className="text-sm">
              Favorite Charity:{" "}
              <a href="https://www.worldwildlife.org/" className="text-yellow">
                worldwildlife.org
              </a>
            </p>
          </div>
        </div>
        <div className="flex flex-col md:w-1/3 space-y-4">
          <div>
            <img alt="Tye" src={tye} />
          </div>
          <div>
            <h3 className="text-4xl mb-1">Tye T.</h3>
            <h4 className="text-yellow mb-6">
              Technomaster & Financial Director
            </h4>
            <p className="text-sm mb-8">
              With a background and tons of experience at a major FinTech
              company, Tyger is able to provide the skill set needed to
              productionize our collection of NFTs such as providing insight
              into project management, financial analysis and technical
              analysis. Without these, our beloved NFTs wouldn't have been able
              to reach the blockchain.
            </p>
            <p className="text-sm">
              Favorite Charity:
              <a href="https://www.greenpeace.org/" className="text-yellow">
                greenpeace.org
              </a>
            </p>
          </div>
        </div>
        <div className="flex flex-col md:w-1/3 space-y-4">
          <div>
            <img alt="Michael" src={michael} />
          </div>
          <div>
            <h3 className="text-4xl mb-1">Michael W.</h3>
            <h4 className="text-yellow mb-6">Architect & Artist</h4>
            <p className="text-sm mb-8">
              From the day M Wave learned about NFTs he aped in. Within days he
              bought into his first project and dove deep into communities to
              learn about NFT innovations. This allowed him to understand what's
              missing from most NFT projects: art people can connect with,
              benefits outside of your wallet, along with building a strong
              community. With the help of Ash and Tye, Rock Pals were born.
            </p>
            <p className="text-sm">
              Favorite Charity:
              <a
                href="https://www.arcticiceproject.org/"
                className="text-yellow"
              >
                arcticiceproject.org
              </a>
            </p>
          </div>
        </div>
      </section>

      <footer className="flex flex-col space-y-10 justify-center items-center mb-10">
        <p className="text-center">
          You've made it this far, follow us on social to stay updated!
        </p>
        <p className="text-center">Rock Pals © 2021</p>
        <div className="flex space-x-6">
          <a
            href="https://discord.gg/rockpals"
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7"
          >
            <img alt="discord" src={discord} />
          </a>
          <a
            href="https://twitter.com/rockpalsnft"
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7"
          >
            <img alt="twitter" src={twitter} />
          </a>
          <a
            href="https://www.instagram.com/rockpalsnft/"
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7"
          >
            <img alt="instagram" src={insta} />
          </a>
        </div>
      </footer>
    </Transition>
  );
};

export default Home;
