import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mint from "./pages/Mint";
import Home from "./pages/Home";
import "./App.css";

// TODO: alt on img, hrefs

function App() {
  return (
    <Router>
      <div className=" max-w-7xl mx-auto px-5 text-white text-sans text-lg leading-relaxed">
        <Routes>
          <Route path="/mint" element={<Mint />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
