import logo from "../assets/Logo.png";
import useContract, { pricing } from "../hooks/useContract";
import useWallet from "../hooks/useWallet";
import abi from "../assets/abi.json";
import SlideOut from "../components/slideOut";

import one from "../assets/packs/1.png";
import three from "../assets/packs/3.png";
import ten from "../assets/packs/10.png";

import onePublic from "../assets/packs/1-public.png";
import threePublic from "../assets/packs/3-public.png";
import tenPublic from "../assets/packs/10-public.png";
import { useEffect, useMemo, useState } from "react";
import Web3 from "web3";

const Mint = () => {
  const [hasFunds, setHasFunds] = useState(true);
  const { address, slicedAddress, gas, connect, balance } = useWallet();
  const {
    mint,
    preMint,
    totalSupply,
    saleActive,
    presaleActive,
    whitelisted,
    minting,
  } = useContract(address, abi);

  const purchaseMessage = useMemo(() => {
    if (!address) {
      return "Not Connected";
    }
    if (minting) {
      return "Minting...";
    }
    if (!presaleActive && !saleActive) {
      return "Sale Inactive";
    }
    if (presaleActive && !whitelisted) {
      return "Not whitelisted";
    }
    if (!hasFunds) {
      return "Insufficient funds";
    }

    return "Purchase";
  }, [address, minting, presaleActive, saleActive, whitelisted, hasFunds]);

  const sendToMM = (amt, isWhitelist) => {
    if (+Web3.utils.toWei(pricing(presaleActive, amt).toString()) > +balance) {
      console.warn(`$${address} does not have sufficient funds.`);
      setHasFunds(false);
      return;
    }
    isWhitelist ? preMint(amt, address) : mint(amt, address);
  };

  useEffect(() => {
    if (whitelisted) {
      console.log("You are whitelisted!");
    }
  }, [whitelisted]);

  return (
    <>
      <nav className="py-10 md:py-20 flex justify-between items-center mb-14">
        <div className="w-1/2 md:w-auto aspect-auto">
          <img alt="Logo" src={logo} />
        </div>
        <div className="space-x-6 flex">
          <button
            onClick={() => connect()}
            className="px-8 flex justify-center items-center py-4 md:px-24 bg-discord uppercase rounded-md"
          >
            {address ? slicedAddress : "Connect"}
          </button>
        </div>
      </nav>

      <main>
        <h1 className="mb-12 uppercase font-bold text-2xl sm:text-4xl md:text-5xl leading-relaxed text-center">
          Mint your googly-eye <b className="text-yellow">rock pal</b>
        </h1>
        <div className="flex flex-col items-center lg:flex-row space-y-12 lg:space-y-0 mb-32">
          <div>
            <img src={saleActive ? onePublic : one} alt="One pack" />
            <button
              disabled={purchaseMessage !== "Purchase"}
              onClick={() => sendToMM(1, presaleActive)}
              className="w-full md:w-1/2 disabled:cursor-not-allowed disabled:opacity-50 mx-auto flex justify-center items-center py-4 px-24 bg-discord uppercase rounded-md"
            >
              {purchaseMessage}
            </button>
          </div>
          <div>
            <img src={saleActive ? threePublic : three} alt="Three pack" />
            <button
              disabled={purchaseMessage !== "Purchase"}
              onClick={() => sendToMM(3, presaleActive)}
              className="w-full md:w-1/2 disabled:cursor-not-allowed disabled:opacity-50 mx-auto flex justify-center items-center py-4 px-24 bg-discord uppercase rounded-md"
            >
              {purchaseMessage}
            </button>
          </div>
          <div>
            <img src={saleActive ? tenPublic : ten} alt="Ten pack" />
            <button
              disabled={purchaseMessage !== "Purchase"}
              onClick={() => sendToMM(10, presaleActive)}
              className="w-full md:w-1/2 disabled:cursor-not-allowed disabled:opacity-50 mx-auto flex justify-center items-center py-4 px-24 bg-discord uppercase rounded-md"
            >
              {purchaseMessage}
            </button>
          </div>
        </div>
      </main>
      <div className="fixed bottom-6 left-4">
        <div className="flex flex-col space-y-2">
          <SlideOut direction="right" content={gas && `${gas} gwei`}>
            <span>Fee per Gas</span>
          </SlideOut>
          <SlideOut direction="right" content={totalSupply}>
            <span>Total Minted</span>
          </SlideOut>
        </div>
      </div>
    </>
  );
};

export default Mint;
